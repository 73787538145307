import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Logon.module.less";
import LoginInput from "../components/LoginInput";
import LoginTab from "../components/LoginTab";
import CommonButton from "../../components/CommonButton";
import InputContainer from "../components/InputContainer";
import CommonNavBar from "views/components/CommonNavBar";
import CodeDown from "../components/CodeDown";
import { Toast } from "react-vant";
import { userLogin } from "api/register";
import { getUserInfo } from "api/profile";
import { commonRules, handleRulesOfUseClick } from "../components/utils";
import DSBridge from "dsbridge";
import { WebSocketSingleton } from "utils/logTool";

const initTabs = [
  {
    key: "code",
    value: "验证码登录"
  },
  {
    key: "password",
    value: "密码登录"
  }
];

function Logon() {
  const navigate = useNavigate();
  const [loginType, setLoginType] = useState("code");
  const [remember, setRemember] = useState(false);
  const [agree, setAgree] = useState(false);
  const [defaultPassword, setDefaultPassword] = useState("");
  const [defaultCode, setDefaultCode] = useState("");
  const inputContainerRef = useRef(null);
  const loginToastRef = useRef(null);
  const getInfo = async () => {
    loginToastRef.current = Toast.loading({
      message: "正在获取用户信息中...",
      forbidClick: true,
      duration: 0
    });
    const infoRes = await getUserInfo();
    loginToastRef.current.clear();
    if (infoRes.code === 200) {
      if (infoRes.data.total_login_count === 1) {
        navigate("/welcome", { replace: true });
      } else {
        const origin = localStorage.getItem("origin");
        if (origin && origin !== "null" && origin !== "undefined") {
          localStorage.removeItem("origin");
          window.location.replace(origin);
        } else {
          navigate("/main/home", { replace: true });
        }
      }
      try {
        WebSocketSingleton.reconnect();
      } catch (e) {
        console.log(e);
      }
    } else {
      navigate("/main/home", { replace: true });
    }
  };
  const handleLogin = async (params) => {
    loginToastRef.current = Toast.loading({
      message: "正在登录中...",
      forbidClick: true,
      duration: 0
    });
    const loginRes = await userLogin(params);
    loginToastRef.current.clear();
    if (loginRes.code === 200) {
      const expirationTime = new Date().getTime() + 60 * 60 * 1000;
      try {
        localStorage.setItem("token_expiration", expirationTime);
        localStorage.setItem("access_token", loginRes.data.access_token);
        localStorage.setItem("refresh_token", loginRes.data.refresh_token);
      } catch (e) {
        console.log(e);
      }
      console.log(
        "获取登录token并设置",
        window,
        window.localStorage,
        expirationTime,
        loginRes,
        loginRes.data.access_token,
        loginRes.data.refresh_token
      );
      getInfo();
    } else {
      console.log("登录失败", loginRes);
    }
  };
  const handleClick = async () => {
    try {
      const params = await inputContainerRef.current.getValues();
      if (!agree) {
        return Toast.info("请在登录前阅读并同意我们的条款");
      }
      handleLogin(params);
    } catch (e) {
      console.log(e);
    }
  };
  const oneClickLogin = () => {
    DSBridge.call("auth.startLogin", {}, (cb) => {
      try {
        cb = JSON.parse(cb);
        if (cb.result === true) {
          const expirationTime = new Date().getTime() + 60 * 60 * 1000;
          localStorage.setItem("token_expiration", expirationTime);
          localStorage.setItem("access_token", cb.data.access_token);
          localStorage.setItem("refresh_token", cb.data.refresh_token);
          getInfo();
        } else {
          Toast.info(cb.message);
        }
      } catch {
        Toast.info(`登录回调信息错误`);
      }
    });
  };
  useEffect(() => {
    oneClickLogin();
  }, []);
  return (
    <div className={styles["register-container"]}>
      <CommonNavBar
        onClickBack={() => {
          navigate(-1);
        }}
      />
      <img
        className={styles["right-banner"]}
        src={require("@/images/login/right-banner.png")}
      />
      <img
        className={styles["top-banner"]}
        src={require("@/images/login/center-banner.png")}
      />
      <LoginTab
        className={styles["login-tab"]}
        dataList={initTabs}
        defaultValue={"code"}
        onSelect={(key) => {
          if (loginType !== key) {
            setLoginType(key);
            if (key === "password") {
              setDefaultCode(
                inputContainerRef.current.getValue("verification_code")
              );
            } else {
              setDefaultPassword(
                inputContainerRef.current.getValue("password")
              );
            }
          }
        }}
      />
      <InputContainer ref={inputContainerRef} rules={commonRules}>
        <LoginInput
          className={styles["login-input"]}
          field="phone_number"
          icon={require("@/images/login/ic_mobile.png")}
          inputMode="numeric"
          placeholderText="请输入手机号"
          selectedIcon={require("@/images/login/ic_mobile_selected.png")}
        />
        {loginType === "password" ? (
          <LoginInput
            className={styles["login-input"]}
            defaultValue={defaultPassword}
            field="password"
            icon={require("@/images/login/ic_pwd.png")}
            key={`login-input-${loginType}`}
            placeholderText="请输入密码"
            selectedIcon={require("@/images/login/ic_pwd_selected.png")}
            type="password"
          />
        ) : (
          <LoginInput
            className={styles["login-input"]}
            defaultValue={defaultCode}
            field="verification_code"
            icon={require("@/images/login/ic_mail.png")}
            inputMode="numeric"
            key={`login-input-${loginType}`}
            placeholderText="请输入验证码"
            selectedIcon={require("@/images/login/ic_mail_selected.png")}
          >
            <CodeDown
              getPhone={() => {
                return inputContainerRef.current.getValue("phone_number");
              }}
            />
          </LoginInput>
        )}
      </InputContainer>
      <div className={styles["operate-wrapper"]}>
        {loginType === "password" ? (
          <>
            <div
              className={styles["operate-wrapper-item"]}
              onClick={() => setRemember(!remember)}
            >
              <div
                className={`${styles["item-icon"]} ${
                  remember ? styles["item-icon--checked"] : ""
                }`}
              ></div>
              <span className={styles["item-text"]}>记住密码</span>
            </div>
            <div
              className={styles["operate-wrapper-item"]}
              onClick={() => {
                navigate("/login/find");
              }}
            >
              <span className={styles["item-text"]}>忘记密码</span>
              <div className={styles["item-icon"]}>?</div>
            </div>
          </>
        ) : null}
      </div>
      <CommonButton delay={500} onClick={handleClick} text="登录" />
      <div
        className={styles["check-way"]}
        onClick={() => {
          navigate("/login/register");
        }}
      >
        <span className={styles["check-way-left"]}>还没有账号？</span>
        <span className={styles["check-way-right"]}>去注册</span>
      </div>
      <div className={styles["bottom-rule"]}>
        <div
          className={`${styles["bottom-rule-icon"]} ${
            agree ? styles["bottom-rule-icon--checked"] : ""
          }`}
          onClick={() => setAgree(!agree)}
        ></div>
        <div className={styles["bottom-rule-text"]}>
          <div>已阅读并同意</div>
          <div
            className={styles["bottom-rule-text--link"]}
            onClick={() => handleRulesOfUseClick(navigate, "app")}
          >
            《使用规则》
          </div>
          <div
            className={styles["bottom-rule-text--link"]}
            onClick={() => handleRulesOfUseClick(navigate, "yszc")}
          >
            《隐私协议》
          </div>
          <div
            className={styles["bottom-rule-text--link"]}
            onClick={() => handleRulesOfUseClick(navigate, "zxky")}
          >
            《服务协议》
          </div>
          <div
            className={styles["bottom-rule-text--link"]}
            onClick={() => handleRulesOfUseClick(navigate, "AI")}
          >
            《平台服务协议》
          </div>
        </div>
      </div>
    </div>
  );
}
export default Logon;
