import { Route } from "react-router-dom";
import { Routes } from "react-router-dom";
import GoodsDetail from "./goods-detail/GoodsDetail";
import MailingAddress from "./mailing-address/MailingAddress";
import MyExchange from "./my-exchange/MyExchange";
import LogisticsInformation from "./logistics-information/LogisticsInformation";
import AccountManage from "./account-manage/AccountManage";
import PointsMall from "./points-mall/PointsMall";
import SystemSetting from "./system-setting/SystemSetting";
import PointsDetail from "./points-detail/PointsDetail";
import PointsCenter from "./points-center/PointsCenter";
import WebUrl from "./web-url/WebUrl";
import Feedback from "./feedback/Feedback";
import Logoff from "./logoff/Logoff";
import ModifyPhone from "./modify-phone/ModifyPhone";
import ModifyPassword from "./modify-password/ModifyPassword";
import WordCollection from "./word-collection/WordCollection";
import ExportPreview from "./export-preview/ExportPreview";
import StudyTogether from "./study-together/StudyTogether";
import RecommendOfficer from "./recommend-officer/RecommendOfficer";
import StudyTogetherWeb from "./study-together/StudyTogetherWeb";
import NotFoundPage from "views/NotFoundPage";
import Service from "./service/Service";
import QuestContent from "./questContent/questContent";
import MyInvite from "./my-invite/MyInvite";
import ClassHourRecord from "./class-hour-record/ClassHourRecord";
import MyOrder from "./my-order/MyOrder";
import StudyTogetherRegister from "./study-together/StudyTogetherRegister";
import CheckIn from "./check-in/CheckIn";
import ExchangeSuccess from "./exchange-success/ExchangeSuccess";
import VideoOne from "./video-one/VideoOne";

function MineManage() {
  return (
    <Routes>
      <Route element={<GoodsDetail />} path="goodsDetail" />
      <Route element={<MailingAddress />} path="mailingAddress" />
      <Route element={<MyExchange />} path="myExchange" />
      <Route element={<LogisticsInformation />} path="logisticsInformation" />
      <Route element={<AccountManage />} path="accountManage" />
      <Route element={<PointsMall />} path="pointsMall" />
      <Route element={<SystemSetting />} path="systemSetting" />
      <Route element={<PointsDetail />} path="pointsDetail" />
      <Route element={<PointsCenter />} path="pointsCenter" />
      <Route element={<Feedback />} path="feedback" />
      <Route element={<Logoff />} path="logoff" />
      <Route element={<WebUrl />} path="webUrl" />
      <Route element={<ModifyPhone />} path="modifyPhone" />
      <Route element={<ModifyPassword />} path="modifyPassword" />
      <Route element={<WordCollection />} path="wordCollection" />
      <Route element={<ExportPreview />} path="exportPreview" />
      <Route element={<StudyTogether />} path="studyTogether" />
      <Route element={<RecommendOfficer />} path="recommendOfficer" />
      <Route element={<StudyTogetherWeb />} path="studyTogetherWeb" />
      <Route element={<Service />} path="service" />
      <Route element={<QuestContent />} path="questContent" />
      <Route element={<MyInvite />} path="myInvite" />
      <Route element={<ClassHourRecord />} path="classHourRecord" />
      <Route element={<MyOrder />} path="myOrder" />
      <Route element={<StudyTogetherRegister />} path="studyTogetherRegister" />
      <Route element={<CheckIn />} path="checkIn" />
      <Route element={<ExchangeSuccess />} path="exchangeSuccess" />
      <Route element={<VideoOne />} path="videoOne" />
      <Route element={<NotFoundPage />} path="*" />
    </Routes>
  );
}

export default MineManage;
