import { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CommonNavBar from "views/components/CommonNavBar";
import styles from "./CoursePackage.module.less";
import { getMaterialGrade } from "api/textbook";
import { setFavoriteCourseware } from "api/materials";
import { Toast, Popover, Dialog } from "react-vant";
import { debounce } from "lodash";
import { ArrowDown, ArrowUp } from "@react-vant/icons";

import TransferPage from "views/study/material/TransferPage";

const screenActions = [
  { text: "全部单元" },
  { text: "已学完" },
  { text: "学习中" },
  { text: "待学习" }
];

const studyStatus = {
  已学完: styles["left-text-status--1"],
  学习中: styles["left-text-status--2"],
  待学习: styles["left-text-status--3"]
};

const moreActions = [{ text: "课程报告" }, { text: "聊天记录" }];
const CoursePackage = () => {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(useLocation().search);
  const loadingRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [courseData, setCourseData] = useState(null);
  const [unitOpen, setUnitOpen] = useState(false);
  const [optionType, setOptionType] = useState("全部单元");
  const [startShow, setStartShow] = useState(false);
  const scrollRef = useRef(null);
  const targetRef = useRef(null);
  const screenSelect = (option) => setOptionType(option.text);
  const moreSelect = (option, item) => {
    if (option.text === "课程报告") {
      navigate(`/studyReport?id=${item.lesson_id}`);
    }
    if (option.text === "聊天记录") {
      navigate(`/mall/chatHistory?lesson_id=${item.lesson_id}`);
    }
  };
  const filterData = () => {
    if (Array.isArray(courseData.data)) {
      if (optionType === "全部单元") {
        return courseData.data;
      } else {
        return courseData.data.filter((item) => item.status === optionType);
      }
    } else {
      return [];
    }
  };
  const loadData = async () => {
    setIsLoading(true);
    const res = await getMaterialGrade({
      series_id: searchParams.get("series_id"),
      grade_id: searchParams.get("grade_id"),
      book_id: searchParams.get("book_id")
    });
    setIsLoading(false);
    if (res.code === 200) {
      if (Array.isArray(res.data?.data)) {
        const copyData = JSON.parse(JSON.stringify(res.data.data));
        copyData.forEach((i) => {
          try {
            if (i.event_time) {
              i.event_time = new Date(i.event_time).getTime();
            } else {
              i.event_time = 0;
            }
          } catch (e) {
            i.event_time = 0;
            console.log(e);
          }
        });
        copyData.sort((a, b) => b.event_time - a.event_time);
        if (
          copyData[0].event_time &&
          res.data.data.find((k) => k.id === copyData[0].id)
        ) {
          res.data.data.find((k) => k.id === copyData[0].id).recently = true;
        }
      }
      setCourseData(res.data);
    }
  };
  const handleFavorite = debounce(
    async () => {
      if (!!!localStorage.getItem("access_token")) {
        navigate("/login/logon");
        return;
      }
      if (courseData.favorite) {
        loadingRef.current = Toast.loading({
          message: "正在取消收藏...",
          forbidClick: true,
          duration: 0
        });
      } else {
        loadingRef.current = Toast.loading({
          message: "正在收藏...",
          forbidClick: true,
          duration: 0
        });
      }
      const res = await setFavoriteCourseware({
        courseware_id: searchParams.get("grade_id")
      });
      loadingRef.current.clear();
      if (res.code === 200) {
        Toast.info(res.message);
        courseData.favorite = !courseData.favorite;
        setCourseData({ ...courseData });
      }
    },
    500,
    {
      leading: true,
      trailing: false
    }
  );
  const handleStartLesson = (item) => {
    if (!!!localStorage.getItem("access_token")) {
      Toast.info("当前操作需要登录!");
      navigate("/login/logon");
      return;
    }
    localStorage.setItem("courseware_name", item.title);
    localStorage.setItem("data_json_path", item.data_json_path);
    localStorage.setItem("material_id", item.id || "");
    let hasTeacher = false;
    try {
      const teacherInfo = JSON.parse(localStorage.getItem("teacher_info"));
      if (teacherInfo.hasOwnProperty("character_id")) {
        hasTeacher = true;
      }
    } catch (e) {
      console.log(e, "没有教师信息，去选择教师");
    }
    if (hasTeacher) {
      setStartShow(true);
    } else {
      navigate("/main/learn/0");
      Dialog.alert({
        message: "请选择任课外教"
      });
    }
  };
  useEffect(() => {
    if (isLoading) {
      loadingRef.current = Toast.loading({
        message: "正在加载...",
        forbidClick: false,
        duration: 0
      });
    }
    return () => {
      if (loadingRef.current) {
        loadingRef.current.clear();
        loadingRef.current = null;
      }
    };
  }, [isLoading]);
  useEffect(() => {
    if (
      searchParams.get("series_id") !== null &&
      searchParams.get("grade_id") !== null &&
      searchParams.get("book_id") !== null
    ) {
      loadData();
    } else {
      Toast.info("暂无数据，请返回重试");
    }
  }, []);
  useEffect(() => {
    if (targetRef.current && scrollRef.current) {
      try {
        setTimeout(() => {
          const fontSize = window.getComputedStyle(
            document.documentElement
          ).fontSize;
          scrollRef.current.scrollTo({
            top:
              targetRef.current?.getBoundingClientRect().top -
              6.25 * Number(fontSize.split("px")[0]),
            behavior: "smooth"
          });
        }, 500);
      } catch (e) {
        console.log(e);
      }
    }
  }, [courseData]);
  return (
    <div className={styles["course-container"]} ref={scrollRef}>
      <CommonNavBar
        title={courseData?.title || ""}
        backgroundColor={"#fff"}
        onClickBack={() => {
          navigate(-1);
        }}
      />
      {courseData ? (
        <>
          <div className={styles["course-header"]}>
            <img
              className={styles["course-header-left"]}
              src={courseData.cover}
            />
            <div className={styles["course-header-center"]}>
              <div className={styles["text-top"]}>
                {courseData.series_title || ""}
              </div>
              <div className={styles["text-center"]}>
                {courseData.title || ""}
              </div>
              <div className={styles["text-bottom"]}>{`共${
                courseData.total_count || 0
              }课时｜${courseData.level}`}</div>
            </div>
            <img
              className={styles["course-header-right"]}
              src={
                courseData.favorite
                  ? require("@/assets/icon/ic_book_list_like.png")
                  : require("@/assets/icon/ic_nav_unlike.png")
              }
              onClick={handleFavorite}
            />
          </div>
          <div className={styles["course-center"]}>
            <div className={styles["course-center-title"]}>教材简介</div>
            <div className={styles["course-center-text"]}>
              {courseData.introduction || ""}
            </div>
          </div>
          <div className={styles["course-bottom"]}>
            <Popover
              placement="bottom-start"
              onOpened={() => setUnitOpen(true)}
              onClosed={() => setUnitOpen(false)}
              actions={screenActions}
              onSelect={screenSelect}
              reference={
                <div className={styles["course-bottom-unit"]}>
                  <span className={styles["unit-title"]}>{optionType}</span>
                  {unitOpen ? <ArrowUp /> : <ArrowDown />}
                </div>
              }
            />
            <div className={styles["course-list"]}>
              {filterData().map((item, index) => (
                <div
                  className={styles["course-list-item"]}
                  key={`couse-item-${optionType}-${index}`}
                >
                  <div className={styles["item-top"]}>
                    <div className={styles["item-top-left"]}>
                      <img
                        className={styles["left-icon"]}
                        src={
                          item.status === "待学习" || !item.status
                            ? require("@/assets/icon/ic_lesson_unit_start_lesson.png")
                            : require("@/assets/icon/ic_lesson_unit_complete.png")
                        }
                      />
                      <div
                        className={`${styles["left-text"]} ${
                          studyStatus[item.status] || ""
                        }`}
                      >
                        {item.status}
                      </div>
                    </div>
                    {item.status === "已学完" ? (
                      <Popover
                        placement="bottom-end"
                        actions={moreActions}
                        onSelect={(option) => moreSelect(option, item)}
                        reference={
                          <div className={styles["item-top-right"]}>更多</div>
                        }
                      />
                    ) : null}
                  </div>
                  <div className={styles["item-cover"]}>
                    <img
                      className={styles["item-cover-pic"]}
                      src={item.cover}
                      onClick={() => handleStartLesson(item)}
                    />
                    {item.status === "已学完" ? (
                      <div
                        className={styles["item-cover-grey"]}
                        onClick={() => handleStartLesson(item)}
                      ></div>
                    ) : null}
                    {item.recently ? (
                      <div
                        ref={targetRef}
                        className={styles["item-cover-recently"]}
                      >
                        最近学习
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={`${styles["item-title"]} ${
                      item.status === "已学完"
                        ? styles["item-title--finish"]
                        : ""
                    }`}
                    onClick={() => handleStartLesson(item)}
                  >
                    {item.title}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : null}
      <TransferPage setVisible={setStartShow} visible={startShow} />
    </div>
  );
};

export default CoursePackage;
