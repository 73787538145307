import { useNavigate, useLocation } from "react-router-dom";
import styles from "./GoodsDetail.module.less";
import { useEffect, useRef, useState } from "react";
import CommonNavBar from "views/components/CommonNavBar";
import CommonButton from "views/components/CommonButton";
import { getAddressList, getGoodsInfo, integralExchange } from "api/points";
import { Toast, Overlay } from "react-vant";
function GoodsDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [goodData, setGoodData] = useState(null);
  const [exchangeShow, setExchangeShow] = useState(false);
  const [couponListShow, setCouponListShow] = useState(false);
  const [defaultAddress, setDefaultAddress] = useState(null);
  const [exchangeWay, setExchangeWay] = useState(null);
  const [selectCoupon, setSelectCoupon] = useState(null);
  const selectCouponRef = useRef(null);
  const addressLoadingRef = useRef(null);
  const handleClickExchange = async () => {
    if (exchangeShow === false) {
      addressLoadingRef.current = Toast.loading({
        message: "正在获取收货地址...",
        forbidClick: true,
        duration: 0
      });
      const res = await getAddressList();
      addressLoadingRef.current.clear();
      if (res.code === 200) {
        if (res.data.length > 0) {
          setDefaultAddress(
            res.data.find((i) => i.is_default === 1) || res.data[0]
          );
        }
        setExchangeShow(true);
      }
    }
  };
  const handleClickConfirm = async () => {
    if (!defaultAddress) {
      return Toast.info("留下地址，才能带宝贝回家哦！");
    }
    addressLoadingRef.current = Toast.loading({
      message: "正在兑换商品...",
      forbidClick: true,
      duration: 0
    });
    const integralForm = new FormData();
    integralForm.append("address_id", defaultAddress?.address_id);
    integralForm.append("goods_id", goodData.goods_id);
    integralForm.append("exchange_way", exchangeWay);
    if (exchangeWay === 2) {
      integralForm.append("coupon_id", selectCouponRef.current.coupon_id);
      integralForm.append(
        "coupon_list_id",
        selectCouponRef.current.coupon_list_id
      );
    }
    const res = await integralExchange(integralForm);
    addressLoadingRef.current.clear();
    if (res.code === 200) {
      Toast.info(res.message);
      navigate("/mine/exchangeSuccess", { replace: true });
    }
  };
  const initData = async (id) => {
    addressLoadingRef.current = Toast.loading({
      message: "正在加载数据...",
      forbidClick: true,
      duration: 0
    });
    const res = await getGoodsInfo(id);
    addressLoadingRef.current.clear();
    if (res.code === 200) {
      try {
        if (res.data.coupon_list.length > 0) {
          setExchangeWay(2);
          selectCouponRef.current = res.data.coupon_list[0];
          setSelectCoupon(res.data.coupon_list[0]);
        } else {
          setExchangeWay(1);
        }
        res.data.coupon_list.forEach((item) => {
          try {
            item.use_start_time = item.use_start_time
              .split(" ")[0]
              .replaceAll("-", ".");
          } catch (e) {
            item.use_start_time = "";
            console.log(e);
          }
          try {
            item.use_end_time = item.use_end_time
              .split(" ")[0]
              .replaceAll("-", ".");
          } catch (e) {
            item.use_end_time = "";
            console.log(e);
          }
        });
        setGoodData(res.data);
        handleClickExchange();
      } catch {
        Toast.info("获取信息失败，请返回重试");
      }
    }
  };
  useEffect(() => {
    if (queryParams.get("id")) {
      initData(queryParams.get("id"));
    } else {
      Toast.info("缺少商品id，请返回重试");
    }
  }, []);
  return (
    <div
      className={styles["goods-detail-container"]}
      style={{ overflow: exchangeShow ? "hidden" : "overlay" }}
    >
      <CommonNavBar
        isTop
        onClickBack={() => {
          navigate(-1);
        }}
      />
      {goodData ? (
        <>
          {goodData.thumb ? (
            <img className={styles["goods-pic"]} src={goodData.thumb} />
          ) : null}
          <div className={styles["goods-desc"]}>
            <div className={styles["goods-desc-block"]}>
              <div className={styles["block-text"]}>{goodData.goods_name}</div>
              <div
                className={styles["block-integral"]}
              >{`${goodData.integral}积分`}</div>
            </div>
            <div className={styles["goods-desc-block"]}>
              <div className={styles["block-title"]}>商品描述</div>
              <div className={styles["block-details"]}></div>
              {goodData.content_img ? (
                <img
                  className={styles["block-content"]}
                  src={goodData.content_img}
                />
              ) : null}
            </div>
          </div>
          <div className={styles["button-wrapper"]}>
            <CommonButton
              delay={500}
              onClick={handleClickExchange}
              text={"立即兑换"}
            />
          </div>
        </>
      ) : null}
      <Overlay visible={exchangeShow} onClick={() => setExchangeShow(false)}>
        <div className={styles["address-pop"]}>
          <div className={styles["address-pop-content"]}>
            <div className={styles["content-header"]}>
              <div className={styles["content-header-title"]}>商品兑换</div>
            </div>
            <div className={styles["content-body"]}>
              {defaultAddress ? (
                <div
                  className={styles["content-address"]}
                  onClick={() => navigate("/mine/mailingAddress")}
                >
                  <div className={styles["content-address-left"]}>
                    <div className={styles["left-title"]}>
                      <span className={styles["left-title-name"]}>
                        {defaultAddress.consignee}
                      </span>
                      <span className={styles["left-title-phone"]}>
                        {defaultAddress.mobile}
                      </span>
                      <img
                        className={styles["left-title-icon"]}
                        src={require("@/images/mine/ic_mine_r_arrow.png")}
                      />
                    </div>
                    <div className={styles["left-address"]}>
                      {`${defaultAddress.province || ""}${
                        defaultAddress.city || ""
                      }${defaultAddress.area || ""}${
                        defaultAddress.address || ""
                      }`}
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className={styles["add-item"]}
                  onClick={() => {
                    navigate("/mine/mailingAddress");
                  }}
                >
                  <img
                    className={styles["add-item-icon"]}
                    src={require("@/images/mine/ic_address_add.png")}
                  />
                  <span className={styles["add-item-text"]}>
                    还没有收获地址，去添加
                  </span>
                </div>
              )}
              <div className={styles["content-goods"]}>
                <img
                  className={styles["content-goods-img"]}
                  src={goodData?.thumb}
                />
                <div className={styles["content-goods-desc"]}>
                  <div className={styles["desc-title"]}>
                    {goodData?.goods_name || ""}
                  </div>
                  <div className={styles["desc-text"]}>{`支付${
                    goodData?.integral || ""
                  }积分`}</div>
                </div>
              </div>
              <div className={styles["content-select"]}>
                {goodData?.coupon_list.length > 0 ? (
                  <div className={styles["select-item"]}>
                    <div className={styles["select-item-left"]}>礼券优惠</div>
                    <div className={styles["select-item-right"]}>
                      {exchangeWay === 2 ? (
                        <>
                          <div
                            className={styles["right-tip"]}
                            onClick={() => {
                              setSelectCoupon(selectCouponRef.current);
                              setCouponListShow(true);
                            }}
                          >
                            已选1张
                          </div>
                          <img
                            className={styles["right-icon"]}
                            src={require("@/images/mine/ic_address_pick.png")}
                          />
                        </>
                      ) : (
                        <>
                          <div
                            className={styles["right-coupon"]}
                            onClick={() => {
                              setSelectCoupon(selectCouponRef.current);
                              setCouponListShow(true);
                            }}
                          >{`${goodData?.coupon_list.length}张可用礼券`}</div>
                          <img
                            className={styles["right-icon"]}
                            src={require("@/images/mine/ic_address_none.png")}
                            onClick={() => setExchangeWay(2)}
                          />
                        </>
                      )}
                    </div>
                  </div>
                ) : null}
                <div className={styles["select-item"]}>
                  <div className={styles["select-item-left"]}>积分余额</div>
                  <div className={styles["select-item-right"]}>
                    <div className={styles["right-points"]}>{`当前可用${
                      goodData?.remain_integral || 0
                    }`}</div>
                    {goodData &&
                    goodData?.remain_integral >= goodData?.integral ? (
                      exchangeWay === 1 ? (
                        <img
                          className={styles["right-icon"]}
                          src={require("@/images/mine/ic_address_pick.png")}
                        />
                      ) : (
                        <img
                          className={styles["right-icon"]}
                          src={require("@/images/mine/ic_address_none.png")}
                          onClick={() => setExchangeWay(1)}
                        />
                      )
                    ) : (
                      <img
                        className={styles["right-icon"]}
                        src={require("@/images/mine/ic_address_disabled.png")}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles["content-footer"]}>
              <CommonButton
                delay={500}
                text={
                  exchangeWay !== 2 &&
                  goodData?.remain_integral < goodData?.integral
                    ? "积分不足，不可兑换"
                    : "确认兑换"
                }
                disabled={
                  exchangeWay !== 2 &&
                  goodData?.remain_integral < goodData?.integral
                }
                onClick={handleClickConfirm}
              />
            </div>
          </div>
        </div>
      </Overlay>
      <Overlay
        visible={couponListShow}
        onClick={() => setCouponListShow(false)}
      >
        <div className={styles["address-pop"]}>
          <div className={styles["address-pop-content"]}>
            <div className={styles["content-header"]}>
              <div className={styles["content-header-title"]}>礼券详情</div>
              <img
                className={styles["content-header-icon"]}
                src={require("@/images/mine/ic_coupon_cancel.png")}
                onClick={() => setCouponListShow(false)}
              />
            </div>
            <div className={styles["content-coupon"]}>
              {goodData && goodData.coupon_list
                ? goodData.coupon_list.map((item, index) => (
                    <div
                      className={styles["content-coupon-item"]}
                      key={`coupon-${index}`}
                    >
                      <div className={styles["item-left"]}>
                        <div className={styles["item-left-title"]}>
                          {item.name}
                        </div>
                        <div className={styles["item-left-text"]}>
                          仅指定礼品可用，当前礼品可用
                        </div>
                        <div className={styles["item-left-text"]}>
                          {`${item.use_start_time || "暂无开始时间"}-${
                            item.use_end_time || "暂无结束时间"
                          }`}
                        </div>
                      </div>
                      {item.id === selectCoupon?.id &&
                      item.coupon_list_id === selectCoupon?.coupon_list_id ? (
                        <img
                          className={styles["item-right"]}
                          src={require("@/images/mine/ic_address_pick.png")}
                        />
                      ) : (
                        <img
                          className={styles["item-right"]}
                          src={require("@/images/mine/ic_address_none.png")}
                          onClick={() => {
                            setSelectCoupon(item);
                          }}
                        />
                      )}
                    </div>
                  ))
                : null}
            </div>
            <div className={styles["content-footer"]}>
              <CommonButton
                delay={500}
                text={"确认"}
                onClick={() => {
                  selectCouponRef.current = selectCoupon;
                  setCouponListShow(false);
                }}
              />
            </div>
          </div>
        </div>
      </Overlay>
    </div>
  );
}
export default GoodsDetail;
