import CommonNavBar from "views/components/CommonNavBar";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./VideoOne.module.less";
const VideoOne = () => {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(useLocation().search);
  return (
    <div className={styles["video-one-container"]}>
      <CommonNavBar
        title={
          searchParams.get("title")
            ? decodeURIComponent(searchParams.get("title"))
            : "视频"
        }
        backgroundColor={"#fff"}
        onClickBack={() => {
          navigate(-1);
        }}
      />
      <div className={styles["video-box"]}>
        {searchParams.get("src") ? (
          <video
            autoPlay
            controls
            src={decodeURIComponent(searchParams.get("src"))}
          ></video>
        ) : (
          <div className={styles["no-video"]}>暂无播放视频，请返回重试</div>
        )}
      </div>
    </div>
  );
};
export default VideoOne;
