import React, { useEffect, useRef, useState } from "react";
import styles from "./CommonVideo.module.less";
const CommonVideo = React.forwardRef(
  (
    {
      videoRef,
      src,
      poster = "https://cdn03.myaitalk.vip/utalkrc/materials/videos/new_topic_talk_l1-1/new_topic_talk-pre_b1-1_lesson_12_110601/2528_dcd9aa8c-a767-4688-999d-4fc5386022a1.mp4?vframe/jpg/offset/1/w/320/h/240",
      controls = false,
      playsInline = true,
      disablePictureInPicture = true,
      disableRemotePlayback = true,
      videoOnPlay = () => {}
    },
    ref
  ) => {
    const [isPlay, setIsPlay] = useState(false);
    const [showButton, setShowButton] = useState(true);
    const timerRef = useRef(null);
    const myRef = videoRef || useRef(null);
    useEffect(() => {
      return () => {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
      };
    }, []);
    return (
      <div
        className={`${styles["common-video-container"]} ${
          !controls ? styles["no-controls"] : ""
        }`}
      >
        <video
          ref={myRef}
          className={styles["video-item"]}
          src={src}
          poster={poster}
          controls={controls}
          playsInline={playsInline}
          disablePictureInPicture={disablePictureInPicture}
          disableRemotePlayback={disableRemotePlayback}
          onPlay={() => {
            videoOnPlay();
            setIsPlay(true);
            if (timerRef.current) {
              clearTimeout(timerRef.current);
            }
            timerRef.current = setTimeout(() => {
              setShowButton(false);
            }, 3000);
          }}
          onPause={() => {
            setIsPlay(false);
            if (timerRef.current) {
              clearTimeout(timerRef.current);
            }
            timerRef.current = setTimeout(() => {
              setShowButton(true);
            }, 3000);
          }}
          onEnded={() => {
            setIsPlay(false);
            if (timerRef.current) {
              clearTimeout(timerRef.current);
            }
            timerRef.current = setTimeout(() => {
              setShowButton(true);
            }, 3000);
          }}
        />
        <div
          className={styles["control-box"]}
          onClick={() => {
            setShowButton(true);
            if (isPlay) {
              if (timerRef.current) {
                clearTimeout(timerRef.current);
              }
              timerRef.current = setTimeout(() => {
                setShowButton(false);
              }, 3000);
            }
          }}
        >
          {!showButton ? null : (
            <img
              className={styles["control-box-button"]}
              onClick={(e) => {
                e.stopPropagation();
                try {
                  if (!isPlay) {
                    myRef.current.play();
                  } else {
                    myRef.current.pause();
                    myRef.current.currentTime = 0;
                  }
                } catch (e) {
                  console.log(e);
                }
              }}
              src={
                !isPlay
                  ? require("@/images/home/play.png")
                  : require("@/images/home/stop.png")
              }
            />
          )}
        </div>
      </div>
    );
  }
);
export default CommonVideo;
