import { useEffect, useRef, useState } from "react";
import { NavBar, Skeleton, Toast } from "react-vant";
import { useNavigate, useSearchParams } from "react-router-dom";
import Sound from "./Sound";
import "./StudyReport.less";
import { lessonReport, shareMyLesson, getSwitch } from "api/inter";
import { getSpeakAudio } from "api/teacher";
import DSBridge from "dsbridge";
export default function StudyReport() {
  const [reportData, setReportData] = useState({});
  const [loading, setLoading] = useState(true);
  const [tipShow, setTipShow] = useState(false);
  const [currentPlay, setCurrentPlay] = useState(null);
  const [shareShow, setShareShow] = useState(false);
  const [sharePic, setSharePic] = useState("");
  const [showClockIn, setShowClockIn] = useState(false);
  const [canClock, setCanClock] = useState(false);
  const audioRef = useRef(null);
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const containerRef = useRef(null);
  async function handleClickPlay(index, type, data) {
    if (!currentPlay || currentPlay !== `${type}-${index}`) {
      audioRef.current.pause();
      if (type === "student") {
        if (data.bin_data) {
          audioRef.current.src = `${process.env.REACT_APP_API_CDN}/${data.bin_data}`;
        } else {
          return Toast.info("暂无音频");
        }
      } else {
        Toast.loading({
          message: "教师音频加载中...",
          forbidClick: true,
          loadingType: "spinner",
          duration: 0
        });
        const teacherRes = await getSpeakAudio({
          token: encodeURIComponent(reportData?.comment?.token),
          voice_id: reportData?.comment?.voice_id,
          text: data.answer
        });
        Toast.clear();
        if (teacherRes.code === 200) {
          audioRef.current.src = `data:audio/wav;base64,${teacherRes.data}`;
        }
      }
      audioRef.current.currentTime = 0;
      audioRef.current.play();
      setCurrentPlay(`${type}-${index}`);
    } else {
      audioRef.current.pause();
    }
  }
  async function getLessonReport(id) {
    const res = await lessonReport(id);
    if (res.code === 200) {
      if (Array.isArray(res?.data?.list)) {
        res.data.list = res.data.list.map((item) => {
          try {
            item.bin_data = JSON.parse(item.bin_data)[0];
          } catch {
            item.bin_data = "";
          }
          return item;
        });
      }
      setReportData(res.data);
      // setTipShow(res.data.ad);
      setLoading(false);
    }
  }
  function transformScore(score) {
    if (typeof score !== "number") {
      return "--";
    }
    if (score >= 90) {
      return "很好";
    }
    if (score >= 60 && score < 90) {
      return "一般";
    }
    if (score < 60) {
      return "待加强";
    }
  }
  function handleBack() {
    navigate(-1);
  }
  function handleOpenShare(v) {
    shareMyLesson(params.get("id")).then((res) => {
      if (res.code === 200) {
        setSharePic(res.data.url);
      }
    });
    setShareShow(v);
  }
  function handleShareType(type) {
    if (!sharePic) {
      Toast("正在生成分享图片，请稍后...");
      return;
    }
    handleOpenShare(false);
    switch (type) {
      case 0: {
        DSBridge.call(
          "share.shareInfo",
          {
            type: "image",
            scene: 0,
            title: "口袋领航外教",
            image: sharePic
          },
          (back) => {
            console.log(back);
          }
        );
        break;
      }
      case 1: {
        DSBridge.call(
          "share.shareInfo",
          {
            type: "image",
            scene: 1,
            title: "口袋领航外教",
            image: sharePic
          },
          (back) => {
            console.log(back);
          }
        );
        break;
      }
      case 2: {
        DSBridge.call(
          "share.saveImage",
          {
            url: sharePic
          },
          (back) => {
            console.log(back);
          }
        );
        break;
      }
      default:
        break;
    }
  }
  async function initSwitch() {
    const switchRes = await getSwitch();
    if (switchRes.code === 200) {
      if (switchRes.data?.applet_status === 1) {
        setCanClock(true);
      } else {
        setCanClock(false);
      }
    }
  }
  useEffect(() => {
    const container = containerRef.current;
    function handleScroll() {
      if (
        container.scrollTop >=
          (container.scrollHeight - container.clientHeight) / 2 &&
        !showClockIn
      ) {
        setShowClockIn(true);
      }
      if (
        container.scrollTop <
          (container.scrollHeight - container.clientHeight) / 2 &&
        showClockIn
      ) {
        setShowClockIn(false);
      }
    }
    container.addEventListener("scroll", handleScroll);
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [showClockIn]);
  useEffect(() => {
    getLessonReport(params.get("id"));
    initSwitch();
    audioRef.current = new Audio();
    function handleStop() {
      audioRef.current.src = "";
      setCurrentPlay(null);
    }
    audioRef.current.addEventListener("pause", handleStop);
    audioRef.current.addEventListener("ended", handleStop);
    return () => {
      audioRef.current.removeEventListener("pause", handleStop);
      audioRef.current.removeEventListener("ended", handleStop);
    };
  }, []);
  return (
    <div className="report-container" ref={containerRef}>
      <NavBar
        onClickLeft={handleBack}
        onClickRight={() => handleOpenShare(true)}
        rightText={
          <img
            className="share-button"
            src={require("@/images/report/share.png")}
          />
        }
        safeAreaInsetTop
        title="学习报告"
      />
      <Skeleton loading={loading} row={5} rowHeight={20} title>
        <div className="hd">
          <div className="hd-id">{`ClassId:${params.get("id") || ""}`}</div>
          {tipShow ? (
            <div className="recommend-tip">
              <div className="recommend-tip-text">
                {reportData?.header?.title || "--"}
              </div>
              <div
                className="recommend-tip-buy"
                onClick={() => {
                  navigate("/pay");
                }}
              ></div>
              <div
                className="recommend-tip-hide"
                onClick={() => {
                  setTipShow(false);
                }}
              ></div>
            </div>
          ) : null}
          <div className="hd-black">
            <p className="hd-black-course">
              <span>{reportData?.header?.studentName || "--"}</span>
              {/* <span className="hd-black-course--title">{`【${
                reportData?.header?.title || "--"
              }】`}</span>
              <span>的学习</span> */}
            </p>
            <p className="hd-black-text">
              <span>{reportData?.header?.level || "--"}</span>
              <span className="color-1">
                {reportData?.header?.title || "--"}
              </span>
              {/* <img
                className="hd-black-text-diamond--left"
                src={require("@/images/report/black-diamond.png")}
              />
              <span className="hd-black-text--name">
                {reportData?.header?.studentName || "--"}
              </span>
              <span>/宝贝</span>
              <img
                className="hd-black-text-diamond--right"
                src={require("@/images/report/black-diamond.png")}
              /> */}
            </p>
            <img
              className="hd-duck"
              src={require("@/images/report/hd-duck.png")}
            />
            <img
              className="hd-ubao"
              src={require("@/images/report/hd-ubao.png")}
            />
            <img
              className="hd-hello"
              src={require("@/images/report/hd-hello.png")}
            />
          </div>
        </div>
        <div className="bd">
          <div
            className={`bd-el-container ${
              !canClock ? "bd-el-container--noClock" : null
            }`}
          >
            <div className="report">
              <div className="report-title">学习报告</div>
              <div className="report-block">
                <div className="block-vocabulary">
                  <div>
                    <span className="block-vocabulary-num">
                      {reportData?.header?.vocabulary || "--"}
                    </span>
                    <span className="block-vocabulary-unit">个</span>
                  </div>
                  <div className="block-vocabulary-name">词汇量</div>
                </div>
                <div className="block-sentence">
                  <div>
                    <span className="block-sentence-num">
                      {reportData?.header?.sentence || "--"}
                    </span>
                    <span className="block-sentence-unit">个</span>
                  </div>
                  <div className="block-sentence-name">句型量</div>
                </div>
                <div className="block-grade">
                  <div>
                    <span className="block-grade-num">
                      {(reportData?.header?.level &&
                        reportData?.header?.level.split(" ")[0]) ||
                        "--"}
                    </span>
                    <span className="block-grade-unit">
                      {(reportData?.header?.level &&
                        reportData?.header?.level.split(" ")[1]) ||
                        "--"}
                    </span>
                  </div>
                  <div className="block-grade-name">当前等级</div>
                </div>
              </div>
              <div className="report-schedule">
                <div>{`等级进度(${reportData?.header?.step ?? "--"}/${
                  reportData?.header?.count ?? "--"
                })`}</div>
                <div>{reportData?.header?.level ?? "--"}</div>
              </div>
              <div className="report-progress">
                <div
                  className="bar"
                  style={{
                    width:
                      typeof reportData?.header?.step === "number" &&
                      typeof reportData?.header?.count === "number"
                        ? `${
                            (reportData?.header?.step /
                              reportData?.header?.count) *
                            100
                          }%`
                        : "0%"
                  }}
                ></div>
              </div>
            </div>
            <div className="connect-container">
              <img
                className="mark-left"
                src={require("@/images/report/connect.png")}
              />
              <img
                className="mark-right"
                src={require("@/images/report/connect.png")}
              />
            </div>
            <div className="percentage">
              <div className="percentage-center">
                <img
                  className="percentage-center-ubao"
                  src={require("@/images/report/percentage-ubao.png")}
                />
              </div>
              {[...new Array(4)].map((item, index) => (
                <img
                  className={`percentage-line-${index + 1}`}
                  key={`percentage-line-${index + 1}`}
                  src={require(
                    `@/images/report/percentage-line-${index + 1}.png`
                  )}
                />
              ))}
              {[...new Array(4)].map((item, index) => (
                <img
                  className={`percentage-round-${index + 1}`}
                  key={`percentage-round-${index + 1}`}
                  src={require("@/images/report/percentage-img-round.png")}
                />
              ))}
              {[...new Array(4)].map((item, index) => (
                <img
                  className={`percentage-icon-${index + 1}`}
                  key={`percentage-img-${index + 1}`}
                  src={require(
                    `@/images/report/percentage-img-${index + 1}.png`
                  )}
                />
              ))}
              <div className="percentage-text-1">
                <div className="title">
                  <span>（</span>
                  <span className="fill">
                    {reportData?.tables?.sayCount || "--"}
                  </span>
                  <span>/次）</span>
                </div>
                <div className="text">开口次数</div>
              </div>
              <div className="percentage-text-2">
                <div className="title">
                  <span>（</span>
                  <span className="fill">
                    {reportData?.tables?.intentCount
                      ? `${reportData?.tables?.intentCount}%`
                      : "--"}
                  </span>
                  <span>）</span>
                </div>
                <div className="text">语义正确率</div>
              </div>
              <div className="percentage-text-3">
                <div className="title">
                  <span>（</span>
                  <span className="fill">
                    {reportData?.tables?.voiceCount
                      ? `${reportData?.tables?.voiceCount}%`
                      : "--"}
                  </span>
                  <span>）</span>
                </div>
                <div className="text">发音正确率</div>
              </div>
              <div className="percentage-text-4">
                <div className="title">
                  <span>（</span>
                  <span className="fill">
                    {reportData?.tables?.starCount || "--"}
                  </span>
                  <span>）</span>
                </div>
                <div className="text">得分数</div>
              </div>
            </div>
            <div className="connect-container">
              <img
                className="mark-left"
                src={require("@/images/report/connect.png")}
              />
              <img
                className="mark-right"
                src={require("@/images/report/connect.png")}
              />
            </div>
            <div className="classroom">
              <img
                className="classroom-title"
                src={require("@/images/report/classroom-title.png")}
              />
              <div className="classroom-head">
                <span className="classroom-head-me">宝贝发音</span>
                <span className="classroom-head-teacher">外教发音</span>
                <span className="classroom-head-eval">评价</span>
              </div>
              {reportData?.list
                ? reportData.list.map((item, index) => (
                    <div className="classroom-item" key={`wav-${index}`}>
                      <div className="classroom-item-word">
                        {item.answer || "--"}
                      </div>
                      <div className="classroom-item-medium">
                        <Sound
                          currentPlay={currentPlay}
                          data={item}
                          handleClickPlay={handleClickPlay}
                          index={index}
                          type={"student"}
                        />
                        <Sound
                          currentPlay={currentPlay}
                          data={item}
                          handleClickPlay={handleClickPlay}
                          index={index}
                          type={"teacher"}
                        />
                        <div className="classroom-item-medium-comment">
                          {transformScore(item.voice_score)}
                        </div>
                      </div>
                    </div>
                  ))
                : null}
            </div>
            <div className="connect-container">
              <img
                className="mark-left"
                src={require("@/images/report/connect.png")}
              />
              <img
                className="mark-right"
                src={require("@/images/report/connect.png")}
              />
            </div>
            <div className="foreign">
              <img
                className="foreign-title"
                src={require("@/images/report/foreign-title.png")}
              />
              <div className="foreign-body">
                <div className="foreign-body-head">
                  <img
                    className="avatar"
                    src={require("@/images/report/foreign-head.png")}
                  />
                  <img
                    className="name"
                    src={require("@/images/report/foreign-name.png")}
                  />
                  <div className="text">{reportData?.comment?.teacher}</div>
                </div>
                <div className="foreign-body-ch">
                  {reportData?.comment?.cntitle}
                </div>
                <div className="foreign-body-en">
                  {reportData?.comment?.entitle}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Skeleton>
      {shareShow ? (
        <div className="report-share">
          <div className="report-share-top">
            <img src={sharePic} />
            {/* <div
              className="close-button"
              onClick={() => handleOpenShare(false)}
            ></div> */}
          </div>
          <div
            className="invite-report-button"
            onClick={() => {
              navigate("/mine/myInvite");
            }}
          >
            邀请记录
          </div>
          <div className="report-share-bottom">
            <div className="button-list">
              <div
                className="button-list-item"
                onClick={() => handleShareType(0)}
              >
                <img className="icon" src={require("@/images/report/wx.png")} />
                <div className="text">微信</div>
              </div>
              <div
                className="button-list-item"
                onClick={() => handleShareType(1)}
              >
                <img
                  className="icon"
                  src={require("@/images/report/friend.png")}
                />
                <div className="text">朋友圈</div>
              </div>
              <div
                className="button-list-item"
                onClick={() => handleShareType(2)}
              >
                <div className="save">
                  <img
                    className="save-icon"
                    src={require("@/images/report/download.png")}
                  />
                </div>
                <div className="text">保存相册</div>
              </div>
            </div>
            <div
              className="cancel-button"
              onClick={() => handleOpenShare(false)}
            >
              取消
            </div>
          </div>
        </div>
      ) : null}
      {showClockIn && canClock ? (
        <div
          className="clock-in"
          onClick={() => {
            navigate("/mine/checkIn");
          }}
        >
          社群打卡
        </div>
      ) : null}
    </div>
  );
}
