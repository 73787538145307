import { useNavigate } from "react-router-dom";
import styles from "./ExchangeSuccess.module.less";
import CommonNavBar from "views/components/CommonNavBar";
const ExchangeSuccess = () => {
  const navigate = useNavigate();
  return (
    <div className={styles["exchange-success-container"]}>
      <CommonNavBar
        isTop
        title={"兑换成功"}
        onClickBack={() => {
          navigate(-1);
        }}
      />
      <div className={styles["icon-box"]}>
        <img
          className={styles["icon-img"]}
          src={require("@/images/mine/ic_exchange_back.png")}
        />
        <div className={styles["icon-text"]}>兑换成功</div>
      </div>
      <div className={styles["button-box"]}>
        <div
          className={styles["button-box-item"]}
          onClick={() => {
            window.location.replace("/");
          }}
        >
          返回首页
        </div>
        <div
          className={styles["button-box-item"]}
          onClick={() => {
            navigate("/mine/myExchange", { replace: true });
          }}
        >
          查看兑换
        </div>
      </div>
    </div>
  );
};
export default ExchangeSuccess;
