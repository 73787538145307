import request from "utils/request";
//报错
export async function uploadErrEvent(data) {
    return request.post("/eventlogs/errorLog.php ", data, "nocache");
}
//记录
export async function uploadIncident(data) {
    return request.post("/eventlogs/eventLog.php", data, "nocache");
}
//对话回答
export async function uploadVoice(data) {
    return request.post("/eventlogs/speakLog.php", data);
}
//学习报告
export async function lessonReport(id) {
    return request.get(`/eventlogs/LessonReport.php?lesson_id=${id}`, "nocache");
}
export async function shareMyLesson(lesson_id = "") {
    return request.get(`/lesson/shareMyLesson.php?lesson_id=${lesson_id}`, "nocache");
}
export async function getSwitch() {
    return request.get(`/version/switch.php`, "nocache");
}