import { useNavigate } from "react-router-dom";
import { Dialog, Toast } from "react-vant";
import styles from "./Mine.module.less";
import { useState, useEffect, useRef } from "react";
import { getUserCourse, getUserProfile } from "api/profile";
import { shareMyLesson, getSwitch } from "api/inter";
import DSBridge from "dsbridge";
import ExchangePop from "./pay/ExchangePop";
import { getLink } from "api/mine";

function Mine() {
  const navigate = useNavigate();
  const mineRef = useRef(null);
  const linkRef = useRef(null);
  const [userData, setUserData] = useState(null);
  const [userInfo, setUserInfo] = useState({});
  const [appInfo, setAppInfo] = useState({});
  const [shareShow, setShareShow] = useState(false);
  const [sharePic, setSharePic] = useState("");
  const shareLoadingRef = useRef(null);
  const [exchangeShow, setExchangeShow] = useState(false);
  const [canClock, setCanClock] = useState(false);
  const [clockUrl, setClockUrl] = useState("");
  const [isLock, setIsLock] = useState(true);
  const initData = async () => {
    const userProfileRes = await getUserProfile();
    if (userProfileRes.code === 200) {
      setUserInfo(userProfileRes.data);
    } else {
      console.log("个人信息解析失败");
    }
    const userRes = await getUserCourse();
    if (userRes.code === 200) {
      setUserData(userRes.data);
    }
    const appInfo = DSBridge.call("getAppInfo");
    setAppInfo(appInfo);
    const switchRes = await getSwitch();
    if (switchRes.code === 200) {
      if (switchRes.data?.applet_status === 1) {
        setCanClock(true);
      } else {
        setClockUrl(switchRes.data?.kefu_link);
        setCanClock(false);
      }
    }
  };
  async function handleOpenShare(v) {
    if (v && !sharePic) {
      shareLoadingRef.current = Toast.loading({
        message: "分享图片生成中...",
        forbidClick: true,
        duration: 0,
      });
      const picRes = await shareMyLesson();
      shareLoadingRef.current.clear();
      if (picRes.code === 200 && typeof picRes?.data?.url === "string") {
        setSharePic(picRes.data.url);
      } else {
        return Toast.info("生成分享图片失败，请重试...");
      }
    }
    setShareShow(v);
  }
  function handleShareType(type) {
    handleOpenShare(false);
    switch (type) {
      case 0: {
        DSBridge.call(
          "share.shareInfo",
          {
            type: "image",
            scene: 0,
            title: "口袋领航外教",
            image: sharePic,
          },
          (back) => {
            console.log(back);
          }
        );
        break;
      }
      case 1: {
        DSBridge.call(
          "share.shareInfo",
          {
            type: "image",
            scene: 1,
            title: "口袋领航外教",
            image: sharePic,
          },
          (back) => {
            console.log(back);
          }
        );
        break;
      }
      case 2: {
        DSBridge.call(
          "share.saveImage",
          {
            url: sharePic,
          },
          (back) => {
            console.log(back);
          }
        );
        break;
      }
      default:
        break;
    }
  }
  const centerJumps = [
    {
      title: "积分中心",
      icon: require("@/images/mine/ic_mine_integral.png"),
      onClick: () => {
        navigate("/mine/pointsCenter");
      },
    },
    {
      title: "单词收藏",
      icon: require("@/images/mine/ic_mine_collection.png"),
      onClick: () => {
        navigate("/mine/wordCollection");
      },
    },
    {
      title: "入门测试",
      icon: require("@/images/mine/ic_mine_test.png"),
      onClick: () => {
        navigate("/introTest");
      },
    },
    {
      title: "社群打卡",
      icon: require("@/images/mine/ic_mine_clock.png"),
      onClick: () => {
        if (canClock) {
          navigate("/mine/checkIn");
        } else {
          navigate(
            `/mine/webUrl?webUrl=${encodeURIComponent(
              clockUrl || ""
            )}&title=${encodeURIComponent("口袋领航外教")}`
          );
        }
      },
    },
    {
      title: "课程兑换",
      icon: require("@/images/mine/ic_mine_exchange.png"),
      onClick: () => {
        setExchangeShow(true);
      },
      isLock: isLock,
    },
    {
      title: "我的订单",
      icon: require("@/images/mine/ic_mine_order.png"),
      onClick: () => {
        navigate("/mine/myOrder");
      },
    },
    {
      title: "课程支付",
      icon: require("@/images/mine/ic_mine_payment.png"),
      onClick: () => {
        navigate("/pay");
      },
    },
    {
      title: "邀请好友",
      icon: require("@/images/mine/ic_mine_invite.png"),
      onClick: () => {
        handleOpenShare(true);
      },
    },
  ];
  const options = [
    {
      title: "加入共学群",
      icon: require("@/images/mine/ic_mine_study.png"),
      onClick: async () => {
        linkRef.current = Toast.loading({
          message: "获取共学群链接中...",
          forbidClick: true,
          duration: 0,
        });
        const res = await getLink();
        linkRef.current.clear();
        if (res.code === 200 && res.data?.group_link) {
          // navigate(
          //   `/webUrlView?title=${encodeURIComponent(
          //     "共学群"
          //   )}&webUrl=${encodeURIComponent(res.data.group_link)}`
          // );
          navigate("/mine/studyTogether");
        } else {
          Toast.info("暂无共学群链接");
        }
      },
    },
    {
      title: "课时记录",
      icon: require("@/images/mine/ic_mine_record.png"),
      onClick: () => {
        //课时记录
        navigate("/mine/classHourRecord");
      },
    },
    {
      title: "隐私协议",
      icon: require("@/images/mine/ic_mine_protocol.png"),
      onClick: () => {
        Dialog.alert({
          title: "隐私协议",
          teleport: mineRef.current,
          closeable: true,
          theme: "round-button",
          message: (
            <div className={styles["dialog-container"]}>
              <div
                className={styles["dialog-container-next"]}
                onClick={() => {
                  navigate(
                    `/mine/webUrl?webUrl=${encodeURIComponent(
                      `${process.env.REACT_APP_API_CDN}/utalkrc/privacy/en/app-permission.html`
                    )}&title=${encodeURIComponent("权限使用说明")}`
                  );
                }}
              >
                权限使用说明
              </div>
              <div
                className={styles["dialog-container-next"]}
                onClick={() => {
                  navigate(
                    `/mine/webUrl?webUrl=${encodeURIComponent(
                      `${process.env.REACT_APP_API_CDN}/utalkrc/privacy/en/platform-service.html`
                    )}&title=${encodeURIComponent("服务协议")}`
                  );
                }}
              >
                服务协议
              </div>
              <div
                className={styles["dialog-container-next"]}
                onClick={() => {
                  navigate(
                    `/mine/webUrl?webUrl=${encodeURIComponent(
                      `${process.env.REACT_APP_API_CDN}/utalkrc/privacy/en/app-privacy-policy.html`
                    )}&title=${encodeURIComponent("隐私协议")}`
                  );
                }}
              >
                隐私协议
              </div>
            </div>
          ),
        });
      },
    },
    {
      title: "APP版本",
      icon: require("@/images/mine/ic_mine_checkupdate.png"),
      onClick: () => {
        Dialog.alert({
          title: "APP版本",
          teleport: mineRef.current,
          closeable: true,
          theme: "round-button",
          message: (
            <div className={styles["dialog-container"]}>
              {`${appInfo?.versionName || ""}${appInfo?.versionCode || ""}` ||
                process.env.REACT_APP_MY_VERSION}
            </div>
          ),
        });
      },
    },
    {
      title: "在线客服",
      icon: require("@/images/mine/ic_mine_service.png"),
      onClick: () => {
        navigate("/mine/service");
      },
    },
    {
      title: "举报反馈",
      icon: require("@/images/mine/ic_mine_feedback.png"),
      onClick: () => {
        navigate("/mine/feedback");
      },
    },
    {
      title: "系统设置",
      icon: require("@/images/mine/ic_mine_settings.png"),
      onClick: () => {
        navigate("/mine/systemSetting", { replace: true });
      },
    },
  ];
  useEffect(() => {
    initData();
    try {
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (userInfo.member_id !== 31) {
        setIsLock(false);
      } else {
        setIsLock(true);
      }
    } catch {
      setIsLock(false);
    }
  }, []);
  return (
    <div className={styles["mine-container"]} ref={mineRef}>
      <img
        className={styles["right-banner"]}
        src={require("@/images/login/right-banner.png")}
      />
      <div className={styles["mine-header"]}>
        <img
          className={styles["mine-header-avatar"]}
          src={
            userInfo.profile_image
              ? `${process.env.REACT_APP_API_CDN}/` + userInfo.profile_image
              : require("@/images/login/ic_avatar.png")
          }
        />
        <div className={styles["mine-header-content"]}>
          <div className={styles["content-name"]}>
            {userInfo?.english_name === undefined || !userInfo?.english_name
              ? "暂无数据"
              : decodeURIComponent(userInfo?.english_name).length <= 15
              ? decodeURIComponent(userInfo?.english_name)
              : decodeURIComponent(userInfo?.english_name).slice(0, 15) + "..."}
          </div>
          {/* <div className={styles["content-id"]}>
            {`ID：${userInfo.phone_number || "--"}`}
          </div> */}
          <div
            className={styles["content-operate"]}
            onClick={() => navigate("/mine/accountManage")}
          >
            <span>账号管理</span>
            <img
              className={styles["content-operate-icon"]}
              src={require("@/images/login/ic_right_arrow.png")}
            />
          </div>
        </div>
      </div>
      <div className={styles["mine-integral"]}>
        <div
          className={styles["mine-integral-item"]}
          onClick={() => {
            navigate("/mine/classHourRecord");
          }}
        >
          <div className={styles["item-hour"]}>
            <span className={styles["item-hour-text"]}>
              {userData ? userData.completedHours : "--"}
            </span>
            {/* <span className={styles.hour}>h</span> */}
          </div>
          <div className={styles["item-desc"]}>已上课时</div>
        </div>
        <div
          className={styles["mine-integral-item"]}
          onClick={() => {
            navigate("/mine/classHourRecord");
          }}
        >
          <div className={styles["item-hour"]}>
            <span className={styles["item-hour-text"]}>
              {userData ? userData.remainingHours : "--"}
            </span>
            {/* <span className={styles.hour}>h</span> */}
          </div>
          <div className={styles["item-desc"]}>剩余课时</div>
        </div>
        <div className={styles["mine-integral-item"]}>
          <div className={styles["item-hour"]}>
            <div className={styles["item-hour-integral"]}>
              {userData ? userData.coursePoints : "--"}
            </div>
          </div>
          <div className={styles["item-desc"]}>课时积分</div>
        </div>
      </div>
      <div className={styles["mine-jump"]}>
        {centerJumps.map((item, index) =>
          !item.isLock ? (
            <div
              className={styles["mine-jump-item"]}
              key={`mine-jump-${index}`}
              onClick={item.onClick}
            >
              <img className={styles["item-icon"]} src={item.icon} />
              <div className={styles["item-text"]}>{item.title}</div>
            </div>
          ) : null
        )}
      </div>
      <div className={styles["mine-option"]}>
        {options.map((item, index) => (
          <div
            className={styles["mine-option-item"]}
            key={`mine-option-${index}`}
            onClick={item.onClick}
          >
            <div className={styles["item-left"]}>
              {item.icon && (
                <img className={styles["item-left-icon"]} src={item.icon} />
              )}
              <div className={styles["item-left-title"]}>{item.title}</div>
            </div>
            <img
              className={styles["item-right"]}
              src={require("@/images/login/ic_right_arrow.png")}
            />
          </div>
        ))}
      </div>
      {shareShow ? (
        <div className={styles["report-share"]}>
          <div className={styles["report-share-top"]}>
            <img src={sharePic} />
            {/* <div
              className={styles["close-button"]}
              onClick={() => handleOpenShare(false)}
            ></div> */}
          </div>
          <div
            className={styles["invite-report-button"]}
            onClick={() => {
              navigate("/mine/myInvite");
            }}
          >
            邀请记录
          </div>
          <div className={styles["report-share-bottom"]}>
            <div className={styles["button-list"]}>
              <div
                className={styles["button-list-item"]}
                onClick={() => handleShareType(0)}
              >
                <img
                  className={styles["icon"]}
                  src={require("@/images/report/wx.png")}
                />
                <div className={styles["text"]}>微信</div>
              </div>
              <div
                className={styles["button-list-item"]}
                onClick={() => handleShareType(1)}
              >
                <img
                  className={styles["icon"]}
                  src={require("@/images/report/friend.png")}
                />
                <div className={styles["text"]}>朋友圈</div>
              </div>
              <div
                className={styles["button-list-item"]}
                onClick={() => handleShareType(2)}
              >
                <div className={styles["save"]}>
                  <img
                    className={styles["save-icon"]}
                    src={require("@/images/report/download.png")}
                  />
                </div>
                <div className={styles["text"]}>保存相册</div>
              </div>
            </div>
            <div
              className={styles["cancel-button"]}
              onClick={() => handleOpenShare(false)}
            >
              取消
            </div>
          </div>
        </div>
      ) : null}
      <ExchangePop show={exchangeShow} setShow={setExchangeShow} />
    </div>
  );
}
export default Mine;
