import StudyToday from "./home/StudyToday";
import Recommended from "./home/Recommended";
import CollectCom from "./home/CollectCom";
import TeacherTop from "./home/TeacherTop";
import Banners from "./home/Banners";
import "./Home.less";
import { useNavigate } from "react-router-dom";
import LabelCom from "./home/LabelCom";
import Draggable from "react-draggable";
import PopView from "./home/PopView";
import HomeVideo from "./home/HomeVideo";
function Home() {
  const navigate = useNavigate();
  return (
    <div className="home-context">
      <StudyToday />
      <div className="divider"></div>
      <LabelCom
        callback={() => navigate("/main/learn/0")}
        link="更多外教"
        title="明星外教"
      />
      <div className="divider"></div>
      <TeacherTop />
      <div className="divider"></div>
      <Banners />
      <div className="divider"></div>
      <LabelCom
        callback={() => navigate("/main/textbook")}
        link="全部课程"
        title="课程系列"
      />
      <div className="divider"></div>
      <Recommended />
      <div className="divider"></div>
      <LabelCom
        callback={() => navigate("/main/textbook")}
        link="全部教材"
        title="收藏教材"
      />
      <div className="divider"></div>
      <CollectCom />
      <div className="divider"></div>
      <HomeVideo />
      <Draggable bounds="parent" cancel=".img">
        <div className="kf">
          <div className="img" onClick={() => navigate("/mine/service")}></div>
          <div className="text">在线客服</div>
        </div>
      </Draggable>
      <PopView />
      {/* </div> */}
    </div>
  );
}

export default Home;
