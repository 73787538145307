import { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getChatHistory } from "api/lesson";
import CommonNavBar from "views/components/CommonNavBar";
import useScrollLoad from "utils/useScrollLoad";
import styles from "./ChatHistory.module.less";
import CommonVideo from "views/components/CommonVideo";
import { ImagePreview, Toast } from "react-vant";

const loadingText = {
  nomore: "已经到底啦～",
  loading: "正在加载～",
  empty: "暂无数据"
};
const ChatHistory = () => {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(useLocation().search);
  const audioRef = useRef(null);
  const [currentPlay, setCurrentPlay] = useState(null);
  const [J_data, setJ_data] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const loadRef = useRef(null);
  const allDataRef = useRef([]);
  const [info, setInfo] = useState([]);
  const urlList = useRef([]);
  function handlePlayAudio(src, index) {
    if (currentPlay === null || currentPlay !== `${index}`) {
      audioRef.current.pause();
      try {
        audioRef.current.src = src;
      } catch {
        return Toast.info("获取音频失败，请重试！");
      }
      audioRef.current.currentTime = 0;
      audioRef.current.play();
      setCurrentPlay(`${index}`);
    } else {
      audioRef.current.pause();
    }
  }
  function handleClickPlay(item, index) {
    if (item.type === "audio") {
      return handlePlayAudio(item.message, index);
    }
    if (item.type === "teacher" || item.type === "user") {
      try {
        if (JSON.parse(item.bin_data).length > 1) {
          urlList.current = JSON.parse(item.bin_data);
          urlList.current.shift();
        }
        const src = `${process.env.REACT_APP_API_CDN}/${
          JSON.parse(item.bin_data)[0]
        }`;
        return handlePlayAudio(src, index);
      } catch (e) {
        console.log(e);
        return Toast.info("音频地址获取失败，请重试！");
      }
    }
    return Toast.info("消息不支持播放，请重试！");
  }
  const fetchData = async () => {
    setIsLoading(true);
    const res = await getChatHistory(searchParams.get("lesson_id"));
    setIsLoading(false);
    const newData = [];
    if (res.code === 200) {
      for (let i = 0; i < res?.data?.data.length; i++) {
        try {
          if (res.data.data[i].type === "audio") {
            if (res.data.data[i].message.includes("[audio:10]")) {
              res.data.data[i].message =
                res.data.data[i].message.split("[audio:10]")[1];
            }
          }
          if (res.data.data[i].type === "video") {
            if (res.data.data[i].message.includes("[video:10]")) {
              res.data.data[i].message =
                res.data.data[i].message.split("[video:10]")[1];
            }
          }
          if (
            res.data.data[i].type === "teacher" ||
            res.data.data[i].type === "user" ||
            res.data.data[i].type === "audio"
          ) {
            res.data.data[i].isPlay = false;
          }
          res.data.data[i].created_at = `${
            res.data.data[i].created_at.split(" ")[1]?.split(":")[0]
          }:${res.data.data[i].created_at.split(" ")[1]?.split(":")[1]}`;
          newData.push(res.data.data[i]);
        } catch (e) {
          console.log(e);
        }
      }
      allDataRef.current = res.data.data;
      setInfo(res.data.info);
      loadData(res.data.data);
    }
  };
  const loadData = (newAllData) => {
    const copyAllData = newAllData || allDataRef.current;
    if (J_data.length < copyAllData.length) {
      setIsLoading(true);
      loadRef.current = setTimeout(() => {
        const nextData = copyAllData.filter(
          (_, index) => index >= J_data.length && index < J_data.length + 50
        );
        setJ_data([...J_data, ...nextData]);
        setIsLoading(false);
      }, 1000);
    }
  };
  const containerRef = useScrollLoad(() => {
    if (!isLoading) {
      loadData();
    }
  });

  useEffect(() => {
    fetchData();
    audioRef.current = new Audio();
    function handlePause() {
      if (audioRef.current.currentTime < audioRef.current.duration) {
        try {
          urlList.current = [];
          audioRef.current.pause();
          audioRef.current.src = "";
          setCurrentPlay(null);
        } catch (e) {
          console.log(e);
        }
      } else {
        handleEnded();
      }
    }
    function handleEnded() {
      if (urlList.current.length > 0) {
        try {
          audioRef.current.src = `${process.env.REACT_APP_API_CDN}/${urlList.current[0]}`;
          urlList.current.shift();
          audioRef.current.currentTime = 0;
          audioRef.current.play();
        } catch {
          return Toast.info("继续播放音频失败，请重试！");
        }
      } else {
        try {
          urlList.current = [];
          audioRef.current.pause();
          audioRef.current.src = "";
          setCurrentPlay(null);
        } catch (e) {
          console.log(e);
        }
      }
    }
    audioRef.current.addEventListener("pause", handlePause);
    return () => {
      if (loadRef.current) {
        clearTimeout(loadRef.current);
      }
      handlePause();
      audioRef.current.removeEventListener("pause", handlePause);
    };
  }, []);
  return (
    <div className={styles["chat-container"]} ref={containerRef}>
      {searchParams.get("nav") !== "false" ? (
        <CommonNavBar
          backgroundColor={"#f5f5f5"}
          title="聊天记录"
          onClickBack={() => {
            navigate(-1);
          }}
        />
      ) : null}

      <div className={styles["chat-list"]}>
        {J_data.map((item, index) => {
          if (item.type === "teacher") {
            return (
              <div
                className={styles["type-teacher"]}
                key={`chat-list-${index}`}
              >
                <img className={styles["head-img"]} src={info.tea_avatar} />
                <div
                  className={styles["type-teacher-box"]}
                  onClick={() => handleClickPlay(item, index)}
                >
                  <div className={styles["box-text"]}>{item.message}</div>
                  <div className={styles["box-icons"]}>
                    {currentPlay === `${index}` ? (
                      <img
                        className={styles["box-icons-item"]}
                        src={require(`@/images/mall/play-black-2.gif`)}
                      />
                    ) : (
                      <img
                        className={styles["box-icons-item"]}
                        src={require(`@/images/mall/play-black-1.png`)}
                      />
                    )}
                  </div>
                </div>
                <div className={styles["type-teacher-time"]}>
                  {item.created_at}
                </div>
              </div>
            );
          }
          if (item.type === "user") {
            return (
              <div className={styles["type-user"]} key={`chat-list-${index}`}>
                <img
                  className={styles["head-img"]}
                  src={
                    typeof info.stu_avatar === "string"
                      ? `${process.env.REACT_APP_API_CDN}${
                          info.stu_avatar[0] === "/" ? "" : "/"
                        }${info.stu_avatar}`
                      : ""
                  }
                />
                <div
                  className={styles["type-user-box"]}
                  onClick={() => handleClickPlay(item, index)}
                >
                  <div className={styles["box-text"]}>{item.message}</div>
                  <div className={styles["box-icons"]}>
                    {currentPlay === `${index}` ? (
                      <img
                        className={styles["box-icons-item"]}
                        src={require(`@/images/mall/play-white-2.gif`)}
                      />
                    ) : (
                      <img
                        className={styles["box-icons-item"]}
                        src={require(`@/images/mall/play-white-1.png`)}
                      />
                    )}
                  </div>
                </div>
                <div className={styles["type-user-time"]}>
                  {item.created_at}
                </div>
              </div>
            );
          }
          if (item.type === "audio") {
            return (
              <div className={styles["type-audio"]} key={`chat-list-${index}`}>
                <img className={styles["head-img"]} src={info.tea_avatar} />
                <div
                  className={styles["type-audio-box"]}
                  onClick={() => handleClickPlay(item, index)}
                >
                  <div className={styles["box-text"]}>课堂音频</div>
                  {currentPlay === `${index}` ? (
                    <img
                      className={styles["box-play"]}
                      src={require(`@/images/mall/play-black-2.gif`)}
                    />
                  ) : (
                    <img
                      className={styles["box-play"]}
                      src={require(`@/images/mall/play-black-1.png`)}
                    />
                  )}
                </div>
                <div className={styles["type-audio-time"]}>
                  {item.created_at}
                </div>
              </div>
            );
          }
          if (item.type === "video") {
            return (
              <div className={styles["type-video"]} key={`chat-list-${index}`}>
                <img className={styles["head-img"]} src={info.tea_avatar} />
                <div className={styles["type-video-box"]}>
                  <CommonVideo
                    src={item.message}
                    poster={
                      item.message
                        ? item.message + "?vframe/jpg/offset/1/w/320/h/180"
                        : ""
                    }
                  />
                </div>
                <div className={styles["type-video-time"]}>
                  {item.created_at}
                </div>
              </div>
            );
          }
          if (item.type === "image") {
            return (
              <div className={styles["type-image"]} key={`chat-list-${index}`}>
                <img className={styles["head-img"]} src={info.tea_avatar} />
                <img
                  className={styles["type-image-box"]}
                  src={item.message}
                  onClick={() => {
                    ImagePreview.open({ images: [item.message] });
                  }}
                />
                <div className={styles["type-image-time"]}>
                  {item.created_at}
                </div>
              </div>
            );
          }
          return <></>;
        })}
        <div className={styles["loading-text"]}>
          {isLoading
            ? loadingText["loading"]
            : J_data.length === 0
            ? loadingText["empty"]
            : loadingText["nomore"]}
        </div>
      </div>
    </div>
  );
};
export default ChatHistory;
