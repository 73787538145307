import { getAppHomeVideo } from "api/home";
import styles from "./HomeVideo.module.less";
import React, { useEffect, useRef, useState } from "react";
import LabelCom from "./LabelCom";
import { Skeleton } from "react-vant";
import CommonModal from "views/components/CommonModal";
const HomeVideo = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedData, setSelectedData] = useState([]);
  const [styleData, setStyleData] = useState([]);
  const [popShow, setPopShow] = useState(false);
  const [src, setSrc] = useState("");
  const playerRef = useRef(null);

  const initData = async () => {
    setIsLoading(true);
    const selectedRes = await getAppHomeVideo({ type: 1 });
    if (selectedRes.code === 200) {
      setSelectedData(selectedRes.data);
    }
    const styleRes = await getAppHomeVideo({ type: 2 });
    if (styleRes.code === 200) {
      setStyleData(styleRes.data);
    }
    setIsLoading(false);
  };
  // const videoListener = () => {
  //   if (!document.fullscreenElement) {
  //     if (playerRef.current) {
  //       playerRef.current.pause();
  //       playerRef.current.removeEventListener(
  //         "fullscreenchange",
  //         videoListener
  //       );
  //     }
  //     setPopShow(false);
  //   }
  // };
  const handleImageClick = (src) => {
    setSrc(src);
    setPopShow(true);
    setTimeout(() => {
      // if (playerRef.current) {
      //   playerRef.current.addEventListener("fullscreenchange", videoListener);
      // }
      playerRef.current.play();
      // if (playerRef.current.requestFullscreen) {
      //   playerRef.current.requestFullscreen();
      // } else if (playerRef.current.webkitRequestFullscreen) {
      //   playerRef.current.webkitRequestFullscreen();
      // }
    }, 100);
  };
  useEffect(() => {
    initData();
  }, []);
  return (
    <div className={styles["home-video-container"]}>
      {selectedData.length === 0 && !isLoading ? null : (
        <>
          <LabelCom title="成长点滴" />
          <div className="divider"></div>
        </>
      )}
      {isLoading ? (
        <Skeleton title />
      ) : (
        <div className={styles["student-selected-list"]}>
          {selectedData.map((item, index) => (
            <div className={styles["video-box"]} key={`student-box-${index}`}>
              <div
                className={styles["video-item"]}
                onClick={() => {
                  handleImageClick(item.video_url);
                }}
              >
                <img
                  className={styles["video-item-cover"]}
                  src={item.cover_img}
                />
                <img
                  className={styles["video-item-play"]}
                  src={require("@/images/home/play.png")}
                />
              </div>
              <div className={styles["title-item"]}>
                {item.english_nickname || ""}
              </div>
            </div>
          ))}
        </div>
      )}
      {styleData.length === 0 && !isLoading ? null : (
        <>
          <div className="divider"></div>
          <LabelCom title="勤学瞬间" />
          <div className="divider"></div>
        </>
      )}
      {isLoading ? (
        <Skeleton title />
      ) : (
        <div className={styles["style-display-list"]}>
          {styleData.map((item, index) => (
            <div className={styles["video-box"]} key={`style-box-${index}`}>
              <div
                className={styles["video-item"]}
                onClick={() => {
                  handleImageClick(item.video_url);
                }}
              >
                <img
                  className={styles["video-item-cover"]}
                  src={item.cover_img}
                />
                <img
                  className={styles["video-item-play"]}
                  src={require("@/images/home/play.png")}
                />
              </div>
              <div className={styles["title-item"]}>
                {item.english_nickname || ""}
              </div>
            </div>
          ))}
        </div>
      )}
      <CommonModal isOpen={popShow}>
        <div className={styles["display-video-container"]}>
          <div className={styles["video-pop-top"]}>
            <img
              className={styles["video-pop-top-close"]}
              src={require("@/images/image/home-close.png")}
              onClick={() => {
                if (playerRef.current) {
                  playerRef.current.pause();
                  // playerRef.current.addEventListener(
                  //   "fullscreenchange",
                  //   videoListener
                  // );
                }
                setPopShow(false);
              }}
            />
          </div>
          <div className={styles["video-pop-box"]}>
            <video
              ref={playerRef}
              src={src}
              preload="auto"
              controls
              autoPlay
              playsInline
              disablePictureInPicture
            ></video>
          </div>
        </div>
      </CommonModal>
    </div>
  );
};
export default HomeVideo;
