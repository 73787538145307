import {useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import CommonNavBar from "views/components/CommonNavBar";
import {getInvite} from "api/mine";
import styles from './MyInvite.module.less';
import {List, PullRefresh, Empty, Toast} from "react-vant";
import {shareMyLesson} from "api/inter";
import DSBridge from "dsbridge";

function myInvite() {
    const navigate = useNavigate();
    const [dataList, setDataList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [finished , setFinished] = useState(false);
    const [lesson_hour, setLessonHour] = useState(0);
    const [shareShow, setShareShow] = useState(false);
    const [sharePic, setSharePic] = useState("");
    const shareLoadingRef = useRef(null);

    async function handleOpenShare(v) {
        if (v && !sharePic) {
            shareLoadingRef.current = Toast.loading({
                message: "分享图片生成中...",
                forbidClick: true,
                duration: 0
            });
            const picRes = await shareMyLesson();
            shareLoadingRef.current.clear();
            if (picRes.code === 200 && typeof picRes?.data?.url === "string") {
                setSharePic(picRes.data.url);
            } else {
                return Toast.info("生成分享图片失败，请重试...");
            }
        }
        setShareShow(v);
    }
    function handleShareType(type) {
        handleOpenShare(false);
        switch (type) {
            case 0: {
                DSBridge.call(
                    "share.shareInfo",
                    {
                        type: "image",
                        scene: 0,
                        title: "MyAi",
                        image: sharePic
                    },
                    (back) => {
                        console.log(back);
                    }
                );
                break;
            }
            case 1: {
                DSBridge.call(
                    "share.shareInfo",
                    {
                        type: "image",
                        scene: 1,
                        title: "MyAi",
                        image: sharePic
                    },
                    (back) => {
                        console.log(back);
                    }
                );
                break;
            }
            case 2: {
                DSBridge.call(
                    "share.saveImage",
                    {
                        url: sharePic
                    },
                    (back) => {
                        console.log(back);
                    }
                );
                break;
            }
            default:
                break;
        }
    }

    const getData = () => {
        getInvite(currentPage).then((res) => {
            setLessonHour(res.data.lesson_hour);
            if (res.data.data.length > 0) {
                const data = res.data.data;
                if(currentPage === 1){
                    setDataList(data)
                }
                if(currentPage > 1){
                    setDataList(dataList => [...dataList, ...data]);
                }
            }
            setFinished(true);
        });
    }

    const onLoad = () => {
        setCurrentPage(currentPage + 1);
        getData();
    }

    useEffect(() => {
        getData()
    },[])

    return (
        <div className={styles.container}>
            <CommonNavBar
                title={"我的邀请"}
                onClickBack={() => {
                    navigate(-1);
                }}
            />
            <img
                className={styles.topBg}
                src={require("@/images/mine/ic_mine_header_bg2.png")}
            />
            <div className={styles.banner} />
            {/*<img*/}
            {/*    className={styles.banner}*/}
            {/*    src={require("@/images/mine/ic_mine_invite_banner.png")}*/}
            {/*/>*/}
            <div className={styles.rule}>
                <div className={styles.title}>
                    已获得<span>{lesson_hour}</span>课时
                </div>
                <div className={styles.content}>
                    活动规则：<br/>
                    1.通过学员海报页面分享至朋友圈或微信，新生通过邀请海报链接进行注册<br/>
                    2.新生成功注册后，进行APP下载并首次登录，双方各送5课时<br/>
                    3.新生需通过好友的邀请链接注册后登录才生效，每个新生手机号仅可领取1次，老生邀请不同新生，老生可叠加获得课时<br/>
                    4.赠送课时有效期为7天<br/>
                    5.此活动与积分商城“新手任务”中邀请好友的积分可以叠加进行<br/><br/>
                </div>
            </div>
            {/*<div className={styles.myRecord}>我的邀请记录</div>*/}
            {/*<PullRefresh onRefresh={onRefresh}>*/}
                <List finished={finished} loading={loading} errorText="加载失败，点击重试" finishedText="没有更多了">
                    <div className={styles.recordList}>
                        { dataList.length > 0 ?
                            dataList.map((item, index) => {
                                return (
                                    <div className={styles.recordItem} key={index}>
                                        <img className={styles.avatar}
                                             src={
                                                 item.profile_image
                                                     ? `${process.env.REACT_APP_API_CDN}/` + item.profile_image
                                                     : require("@/images/login/ic_avatar.png")}
                                        />
                                        <div className={styles.center}>
                                            <div className={styles.nick}>
                                                {item.english_name}
                                            </div>
                                            <div className={styles.phone}>{item.phone_number}</div>
                                        </div>
                                        <div className={styles.status}>{item.user_status}</div>
                                    </div>
                                )
                            }) : <Empty
                                className="custom-image"
                                image={<img src={require("@/images/mine/ic_mine_empty.png")} style={{width:140,height:160}} />}
                            />
                        }
                    </div>
                </List>
            {/*</PullRefresh>*/}
            <div className={styles.inviteBtn} onClick={()=>handleOpenShare(true)}>立即邀请</div>
            {shareShow ? (
                <div className={styles["report-share"]}>
                    <div className={styles["report-share-top"]}>
                        <img src={sharePic} />
                    </div>
                    <div className={styles["report-share-bottom"]}>
                        <div className={styles["button-list"]}>
                            <div
                                className={styles["button-list-item"]}
                                onClick={() => handleShareType(0)}
                            >
                                <img
                                    className={styles["icon"]}
                                    src={require("@/images/report/wx.png")}
                                />
                                <div className={styles["text"]}>微信</div>
                            </div>
                            <div
                                className={styles["button-list-item"]}
                                onClick={() => handleShareType(1)}
                            >
                                <img
                                    className={styles["icon"]}
                                    src={require("@/images/report/friend.png")}
                                />
                                <div className={styles["text"]}>朋友圈</div>
                            </div>
                            <div
                                className={styles["button-list-item"]}
                                onClick={() => handleShareType(2)}
                            >
                                <div className={styles["save"]}>
                                    <img
                                        className={styles["save-icon"]}
                                        src={require("@/images/report/download.png")}
                                    />
                                </div>
                                <div className={styles["text"]}>保存相册</div>
                            </div>
                        </div>
                        <div
                            className={styles["cancel-button"]}
                            onClick={() => handleOpenShare(false)}
                        >
                            取消
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default myInvite;
