import styles from "./CheckIn.module.less";
import CommonNavBar from "views/components/CommonNavBar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DSBridge from "dsbridge";
import { appCardQr } from "api/mine";
import { Toast } from "react-vant";
const CheckIn = () => {
  const navigate = useNavigate();
  const [checkData, setCheckData] = useState(null);
  const initData = async () => {
    Toast.loading({
      message: "打卡信息加载中...",
      forbidClick: true,
      duration: 0
    });
    const res = await appCardQr();
    Toast.clear();
    if (res.code === 200) {
      setCheckData(res.data);
    }
  };
  const handleCheckIn = () => {
    try {
      if (DSBridge.hasNativeMethod("route.navigation")) {
        DSBridge.call(
          "route.navigation",
          {
            route: "miniProgram",
            mid: "gh_ce82ce6b1629",
            path: checkData?.link_url
          },
          (back) => {
            console.log(back);
          }
        );
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    initData();
  }, []);
  return (
    <div className={styles["check-in-container"]}>
      <CommonNavBar
        backgroundColor="#fff"
        onClickBack={() => {
          navigate(-1);
        }}
        title="社群打卡"
      />
      <img
        className={styles["top-img"]}
        src={require("@/images/mine/check-in/top-img.png")}
      />
      {checkData && checkData.type === 2 ? (
        <div className={styles["isCheck-box"]}>
          <div className={styles["isCheck-box-text--1"]}>参与方式一</div>
          <div className={styles["isCheck-box-text--2"]}>
            用其他打卡设备微信扫描小程序进入打卡
          </div>
          <img
            className={styles["isCheck-box-code"]}
            src={checkData.qr_url}
            onClick={() => {
              DSBridge.call(
                "share.saveImage",
                {
                  url: checkData.qr_url
                },
                (back) => {
                  console.log(back);
                }
              );
            }}
          />
          <div className={styles["isCheck-box-text--3"]}>参与方式二</div>
          <div className={styles["isCheck-box-text--4"]}>
            用本设备微信直接参与打卡
          </div>
          <img
            className={styles["isCheck-box-button"]}
            src={require("@/images/mine/check-in/button.png")}
            onClick={() => handleCheckIn()}
          />
          <img
            className={styles["isCheck-box-bottom"]}
            src={require("@/images/mine/check-in/bottom-img.png")}
          />
        </div>
      ) : null}
      {checkData && checkData.type === 1 ? (
        <div className={styles["noCheck-box"]}>
          <div className={styles["noCheck-box-text--1"]}>
            您还没有任何打卡数据哦！
          </div>
          <div className={styles["noCheck-box-text--2"]}>
            请添加助教老师，加入打卡活动吧！
          </div>
          <img
            className={styles["noCheck-box-code"]}
            src={checkData.qr_url}
            onClick={() => {
              DSBridge.call(
                "share.saveImage",
                {
                  url: checkData.qr_url
                },
                (back) => {
                  console.log(back);
                }
              );
            }}
          />
          <div className={styles["noCheck-box-text--3"]}>
            长按识别二维码添加
          </div>
          <img
            className={styles["noCheck-box-bottom"]}
            src={require("@/images/mine/check-in/bottom-img.png")}
          />
        </div>
      ) : null}
    </div>
  );
};

export default CheckIn;
