import { useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import CommonNavBar from "views/components/CommonNavBar";
import { Toast } from "react-vant";
import styles from "./PointsCenter.module.less";
import { getIntegralList } from "api/points";

function PointsCenter() {
  const navigate = useNavigate();
  const [taskTab, setTaskTab] = useState(0);
  const [pointsData, setPointsData] = useState(null);
  const pointsLoadingRef = useRef(null);
  const initData = async () => {
    pointsLoadingRef.current = Toast.loading({
      message: "积分信息获取中...",
      forbidClick: true,
      duration: 0
    });
    const res = await getIntegralList();
    pointsLoadingRef.current.clear();
    if (res.code === 200) {
      if (res?.data?.task["常规任务"]) {
        res.data.task["常规任务"].forEach((item) => {
          if (item.title === "连续30天完成课程") {
            item.path = "/main/textbook";
            item.icon = require("@/images/mine/ic_integration_check.png");
          }
        });
      }
      if (res?.data?.task["新手任务"]) {
        res.data.task["新手任务"].forEach((item) => {
          if (item.title === "注册完成体验课") {
            item.path = "/welcome";
            item.icon = require("@/images/mine/ic_integration_course.png");
          }
          if (item.title === "收藏教材") {
            item.path = "/main/textbook";
            item.icon = require("@/images/mine/ic_integration_collect.png");
          }
          if (item.title === "收藏外教") {
            item.path = "/main/learn/0";
            item.icon = require("@/images/mine/ic_integration_foreign.png");
          }
        });
      }
      if (res?.data?.task["每日任务"]) {
        res.data.task["每日任务"].forEach((item) => {
          if (item.title === "完成3节课程") {
            item.path = "/main/textbook";
            item.icon = require("@/images/mine/ic_integration_course.png");
          }
          if (item.title === "分享3次课程报告") {
            item.path = "/main/course/2";
            item.icon = require("@/images/mine/ic_integration_share.png");
          }
          if (item.title === "邀请好友注册") {
            item.path = "/main/mine";
            item.icon = require("@/images/mine/ic_integration_invite.png");
          }
        });
      }
      setPointsData(res.data);
    }
  };
  useEffect(() => {
    initData();
  }, []);
  return (
    <div className={styles["points-center-container"]}>
      <CommonNavBar
        onClickBack={() => {
          navigate(-1);
        }}
        title="积分中心"
      />
      <img
        className={styles["right-banner"]}
        src={require("@/images/mine/img_integration_top_bg.png")}
      />
      <div className={styles["points-header"]}>
        <div className={styles["points-header-left"]}>
          <span className={styles["left-title"]}>
            {pointsData?.integral?.integral}
          </span>
          <span className={styles["left-text"]}>积分</span>
        </div>
        <div
          className={styles["points-header-right"]}
          onClick={() =>
            navigate(
              `/mine/pointsDetail?integral=${pointsData?.integral?.integral}`
            )
          }
        >
          <img
            className={styles["right-icon"]}
            src={require("@/images/mine/ic_integration_detail_coin.png")}
          />
          <span className={styles["right-text"]}>积分明细</span>
        </div>
      </div>
      <div className={styles["points-jump"]}>
        <img
          className={styles["points-jump-item"]}
          onClick={() => navigate(`/mine/pointsMall?integral`)}
          src={require("@/images/mine/img_integration_store_card.png")}
        />
        <img
          className={styles["points-jump-item"]}
          onClick={() => navigate("/mine/myExchange")}
          src={require("@/images/mine/img_integration_order_card.png")}
        />
      </div>
      {pointsData ? (
        <div className={styles["points-task-list"]}>
          {pointsData?.task["常规任务"] ? (
            <div className={styles["common-list"]}>
              <div className={styles["common-list-title"]}>常规任务</div>
              {pointsData.task["常规任务"].map((item, index) => (
                <div
                  className={styles["common-list-item"]}
                  key={`pointsData-common-${index}`}
                >
                  <div className={styles["item-left"]}>
                    <img
                      className={styles["item-left-icon"]}
                      src={require("@/images/mine/ic_integration_check.png")}
                    />
                    <div className={styles["item-left-content"]}>
                      <div className={styles["content-title"]}>
                        {`${item.title}（${item.accumulate}/${item.number}）`}
                      </div>
                      <div className={styles["content-text"]}>
                        <img
                          className={styles["content-text-icon"]}
                          src={require("@/images/mine/ic_integration_point.png")}
                        />
                        <span>{`${item.integral}积分`}</span>
                      </div>
                    </div>
                  </div>
                  {item.complete_status === "未完成" ? (
                    <div
                      className={styles["item-right--unfinish"]}
                      onClick={() => {
                        if (item.path) {
                          navigate(item.path, { replace: true });
                        }
                      }}
                    >
                      去完成
                    </div>
                  ) : (
                    <div className={styles["item-right--finish"]}>
                      <img
                        className={styles["finish-icon"]}
                        src={require("@/images/mine/ic_integration_complete.png")}
                      />
                      <span>已完成</span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : null}
          <div className={styles["common-list"]}>
            <div className={styles["common-list-tab"]}>
              <div
                className={`${styles["tab-item"]} ${
                  taskTab === 0 ? styles["tab-item--select"] : ""
                }`}
                onClick={() => setTaskTab(0)}
              >
                每日任务
              </div>
              <div
                className={`${styles["tab-item"]} ${
                  taskTab === 1 ? styles["tab-item--select"] : ""
                }`}
                onClick={() => setTaskTab(1)}
              >
                新手任务
              </div>
            </div>
            {taskTab === 0 ? (
              <>
                {pointsData.task["每日任务"]
                  ? pointsData.task["每日任务"].map((item, index) => (
                      <div key={`points-day-${index}`}>
                        <div className={styles["common-list-item"]}>
                          <div className={styles["item-left"]}>
                            <img
                              className={styles["item-left-icon"]}
                              src={item.icon}
                            />
                            <div className={styles["item-left-content"]}>
                              <div className={styles["content-title"]}>
                                {item.title}
                              </div>
                              <div className={styles["content-text"]}>
                                <img
                                  className={styles["content-text-icon"]}
                                  src={require("@/images/mine/ic_integration_point.png")}
                                />
                                <span>{`${item.integral}积分`}</span>
                              </div>
                            </div>
                          </div>
                          {item.complete_status === "未完成" ? (
                            <div
                              className={styles["item-right--unfinish"]}
                              onClick={() => {
                                if (item.path) {
                                  navigate(item.path, { replace: true });
                                }
                              }}
                            >
                              去完成
                            </div>
                          ) : (
                            <div className={styles["item-right--finish"]}>
                              <img
                                className={styles["finish-icon"]}
                                src={require("@/images/mine/ic_integration_complete.png")}
                              />
                              <span>已完成</span>
                            </div>
                          )}
                        </div>
                        {item.complete_status === "未完成" ? (
                          <div className={styles["common-list-line"]}>
                            <div className={styles["line-top"]}>
                              <div className={styles["line-top-back"]}>
                                {Array(3)
                                  .fill(0)
                                  .map((_, lineIndex) =>
                                    lineIndex < item.accumulate ? (
                                      <img
                                        className={
                                          styles["line-top-back-finish"]
                                        }
                                        key={`line-day-finish-${lineIndex}`}
                                        src={require("@/images/mine/ic_integration_complete.png")}
                                      />
                                    ) : (
                                      <div
                                        className={
                                          styles["line-top-back-unfinish"]
                                        }
                                        key={`line-day-unfinish-${lineIndex}`}
                                      ></div>
                                    )
                                  )}
                              </div>
                            </div>
                            <div className={styles["line-bottom"]}>
                              <span
                                className={
                                  item.accumulate > 0
                                    ? styles["line-bottom-text--finish"]
                                    : ""
                                }
                              >
                                完成1次
                              </span>
                              <span
                                className={
                                  item.accumulate > 1
                                    ? styles["line-bottom-text--finish"]
                                    : ""
                                }
                              >
                                完成2次
                              </span>
                              <span
                                className={
                                  item.accumulate > 2
                                    ? styles["line-bottom-text--finish"]
                                    : ""
                                }
                              >
                                最后一次
                              </span>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ))
                  : null}
              </>
            ) : (
              <>
                {pointsData.task["新手任务"]
                  ? pointsData.task["新手任务"].map((item, index) => (
                      <div key={`points-day-${index}`}>
                        <div className={styles["common-list-item"]}>
                          <div className={styles["item-left"]}>
                            <img
                              className={styles["item-left-icon"]}
                              src={item.icon}
                            />
                            <div className={styles["item-left-content"]}>
                              <div className={styles["content-title"]}>
                                {item.title}
                              </div>
                              <div className={styles["content-text"]}>
                                <img
                                  className={styles["content-text-icon"]}
                                  src={require("@/images/mine/ic_integration_point.png")}
                                />
                                <span>{`${item.integral}积分`}</span>
                              </div>
                            </div>
                          </div>
                          {item.complete_status === "未完成" ? (
                            <div
                              className={styles["item-right--unfinish"]}
                              onClick={() => {
                                if (item.path) {
                                  navigate(item.path, { replace: true });
                                }
                              }}
                            >
                              去完成
                            </div>
                          ) : (
                            <div className={styles["item-right--finish"]}>
                              <img
                                className={styles["finish-icon"]}
                                src={require("@/images/mine/ic_integration_complete.png")}
                              />
                              <span>已完成</span>
                            </div>
                          )}
                        </div>
                        {item.complete_status === "未完成" ? (
                          <div className={styles["common-list-line"]}>
                            <div className={styles["line-top"]}>
                              <div className={styles["line-top-back"]}>
                                {Array(3)
                                  .fill(0)
                                  .map((_, lineIndex) =>
                                    lineIndex < item.accumulate ? (
                                      <img
                                        className={
                                          styles["line-top-back-finish"]
                                        }
                                        key={`line-day-finish-${lineIndex}`}
                                        src={require("@/images/mine/ic_integration_complete.png")}
                                      />
                                    ) : (
                                      <div
                                        className={
                                          styles["line-top-back-unfinish"]
                                        }
                                        key={`line-day-unfinish-${lineIndex}`}
                                      ></div>
                                    )
                                  )}
                              </div>
                            </div>
                            <div className={styles["line-bottom"]}>
                              <span
                                className={
                                  item.accumulate > 0
                                    ? styles["line-bottom-text--finish"]
                                    : ""
                                }
                              >
                                完成1次
                              </span>
                              <span
                                className={
                                  item.accumulate > 1
                                    ? styles["line-bottom-text--finish"]
                                    : ""
                                }
                              >
                                完成2次
                              </span>
                              <span
                                className={
                                  item.accumulate > 2
                                    ? styles["line-bottom-text--finish"]
                                    : ""
                                }
                              >
                                最后一次
                              </span>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ))
                  : null}
              </>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
}
export default PointsCenter;
